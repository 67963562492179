import React from 'react';
// import React, { useState } from 'react';

export default function App() {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  return (
    <div
      style={{
        width: '100%',
        // maxWidth: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="App"
    >
      <img
        style={{
          width: '20%',
          marginTop: '50px',
        }}
        src="./logo.webp"
        alt="Мирагриль"
      />
      <h1
        style={{
          textAlign: 'center',
          marginBottom: '0px',
        }}
      >
        МираГриль
      </h1>
      <br />
      <h2
        style={{
          textAlign: 'center',
          marginTop: '0px',
        }}
      >
        ресторан и стэйк-хаус на проспекте Мира
      </h2>
      {/* <img style={{ width: '100vw' }} src="/images/kolonna.webp" alt="" /> */}
      {/* <img
        style={{
          width: '200px',
          position: 'absolute',
          // transform: 'rotate(90deg)',
          left: isHovered ? '-20px' : '-190px',
          transition: 'left 0.3s ease',
        }}
        src="/images/meat.webp"
        alt=""
      /> */}
      {/* <img
        style={{
          width: '240px',
          position: 'absolute',
          // transform: 'rotate(-90deg)',
          right: isHovered ? '-20px' : '-190px',
          transition: 'right 0.3s ease',
        }}
        src="/images/vine.webp"
        alt=""
      /> */}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <a
          target="blank"
          style={{
            textDecoration: 'none',
            margin: '0 15px',
            cursor: 'pointer',
          }}
          href="/miragrill-menu.pdf"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          <h3>меню</h3>
        </a>
        {/* Повторите для других элементов h3, если нужно */}
        <a
          target="blank"
          style={{ textDecoration: 'none', margin: '0 15px' }}
          href="/miragrill-bar.pdf"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          <h3>барная карта</h3>
        </a>
        <a
          target="blank"
          style={{ textDecoration: 'none', margin: '0 15px' }}
          href="https://yandex.ru/maps/213/moscow/?ll=37.617700%2C55.755863&mode=routes&no-distribution=1&profile-mode=1&rtext=~55.816081%2C37.639572&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D133134107506&source=wizbiz_new_map_single&z=10"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          <h3>как добраться</h3>
        </a>
      </div>
    </div>
  );
}
