import React from 'react';
import ReactDOM from 'react-dom/client';
// import { render } from 'react-snapshot';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />,
);

// render(
//   <App />,
//   document.getElementById('root'),
// );
